<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data () {
    return {};
  },
  components: {},
  mounted () {
    // 调用写入登录信息
    this.$axios.get(`/jump.php`)
      .then((res) => {

      })
      .catch((err) => {
        console.log(err);
      });
    //解决安卓点击input，弹出键盘挡住input的问题
    this.andriodInputBug();
  },
  comments: {},
  watch: {
    // 监听路由变化
    $route (to, from) {


    },
  },
  methods: {
    //解决安卓点击input，弹出键盘挡住input的问题
    andriodInputBug () {
      if (/Android/gi.test(navigator.userAgent)) {
        window.addEventListener("resize", function () {
          if (
            document.activeElement.tagName == "INPUT" ||
            document.activeElement.tagName == "TEXTAREA"
          ) {
            window.setTimeout(function () {
              document.activeElement.scrollIntoViewIfNeeded();
            }, 0);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// scss语言
</style>
