//state
const state = {
  // 广告位置
  adSlots: [1,2,34,4,5]
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  //设置城市列表
  setAdSlots (state, adSlots) {
    state.adSlots = adSlots
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}


