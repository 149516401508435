import axios from 'axios'
import qs from 'qs'
//初始化
function install(Vue, opt) {
  //创建axios实例
  const instance = axios.create({
    timeout: 10000, // 超时
    withCredentials: true,

  })
  // 添加请求拦截器
  instance.interceptors.request.use(function (config) {
    // 默认为正在热映/即将上映
    let host = "mall.film-ticket.film.list";
    // 轮播图
    if (config.url == "/gateway?type=2&cityId=440300&k=2887398") {
      host = "mall.cfg.common-banner";
    }
    // .....后续如果有其他的数据请求需求，就接着写
    if (config.headers.authorization) {
      config.headers = {
        authorization: config.headers.authorization,
      };
    } else {
      config.headers = {
        "X-Client-Info":
          '{"a":"3000","ch":"1002","v":"5.0.4","e":"16006566672048699400193","bc":"110100"}',
        "X-Host": host,
      };
    }
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  })

  // 添加响应拦截器
  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  })
  //添加全局方法
  Vue.prototype.$axios = instance
}

export default {
  install
}