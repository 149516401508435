import "babel-polyfill"; //解决安卓4.X兼容性问题
import Vue from "vue";
import App from "./App.vue";
//使用vuex
import Store from "./store";
//使用axios
import Axios from "./axios";
//使用vue-router
import Router from "./router";
// 引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// 引入vant
import Vant from "vant";
import "vant/lib/index.css";

import Mint from "mint-ui";
import "mint-ui/lib/style.css";
Vue.use(Mint);

import "swiper/dist/css/swiper.css"; //在全局没引入，这里记得要！
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);
// import vConsole from 'vconsole'; // 移动端调试工具，stag及以后阶段去掉
// new vConsole();

//引入公共样式
import "./assets/css/public.scss";
// 引入iconfont
import "./assets/css/iconfont.css";

Vue.use(Store);
Vue.use(Axios, { mock: false });
Vue.use(Router);
Vue.use(ElementUI);
Vue.use(Vant);

new Vue({
    el: "#app",
    render: (h) => h(App),
    router: Router.router,
    store: Store.store,
});
